<template>
  <div>
    <div v-if="img !== null">
      <img :src="img" :class="classChange" />
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/storage'

export default {
  props: {
    pathImage: {
      type: String
    },
    className: {
      type: String
    }
  },

  data() {
    return {
      img: null
    }
  },

  computed: {
    classChange() {
      return this.className
    }
  },

  mounted() {
    this.$watch('pathImage', pathImage => {
      this.fetchImage(pathImage)
    }, { immediate: true });
  },

  methods: {
    async fetchImage(pathImage) {
      if (typeof pathImage !== 'undefined' && pathImage !== null && pathImage !== '') {
        await firebase
          .storage()
          .refFromURL(pathImage)
          .getDownloadURL()
          .then(url => {
            this.img = url
          })
          .catch(function (err) {
            console.log("Erro Firebase (fetchImage)", err);
          });
      }
    }
  }
};
</script>

<style>
</style>
